// React
import React, { useState, useEffect } from "react";
import {
    Flex, Box, Image, Text, Button,
    InputGroup, Input, InputRightElement,
    Grid, GridItem,
    TableContainer, Table, Thead, Tfoot, Tbody, Th, Tr, Td
} from "@chakra-ui/react";
import axios from 'axios';
// Components
import KeyPadComponent from "./component/KeyPadComponent.js"
import AmountInputComponent from "./component/AmountInputComponent.js"
// assets
import main_sale from "../../../../../assets/img/service/main_sale.png";
import home from "../../../../../assets/img/service/home.png";



export default function PrePayCard({
    setNowComponent, prepayReq , prepayStatus
}) {

    const [prePayCardComponent, setPrePayCardComponent] = useState("prepay");

    // 조회 시 전화번호 다시 입력 하게 
    useEffect(() => {
        setInputValues(['', '', '', '']);
    }, [prePayCardComponent]);


    const [alertText, setAlertText] = useState('');
    const [alertAni, setAlertAni] = useState('');
    const [inputValues, setInputValues] = useState(['', '', '', '']);

    const handleInputChange = (value) => {
        setAlertText('');
        setAlertAni('');
        const newInputValues = [...inputValues];
        var index = 3;
        for (var i = 3; i >= 0; i--) {
            if (newInputValues[i] === '') {
                index = i;
            }
        }
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };

    // pwd 가 비었냐 
    const hasEmptyValue = inputValues.some(value => value === '');

    const [inputValue, setInputValue] = useState(() => formatNumber("0"));

    // 숫자 형식을 지정하는 함수 (숫자에 3자리마다 콤마를 추가)
    function formatNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 금액 버튼을 처리하는 함수
    const handleAmountChange = (amount) => {
        const currentAmount = parseInt(inputValue.replace(/,/g, ''), 10) || 0;
        setInputValue(formatNumber(currentAmount + amount));
    };

    // 초기화 버튼을 처리하는 함수
    const resetInput = () => {
        setInputValue(formatNumber(0));
    };

    // 충전 추가 퍼센트 금액
    const [inputPerValue, setInputPerValue] = useState(() => formatNumber("0"));

    // 적립 퍼센트 
    const [totalPerCentValue, setTotalPerCentValue] = useState("");

    // 숫자 형식을 지정하는 함수 (숫자에 3자리마다 콤마를 추가)
    function formatNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 퍼센트 버튼을 처리하는 함수
    const handlePerChange = (amount) => {

        //충전 요청금액의 값 
        const prepayReqAmount = parseInt(inputValue.replace(/,/g, ''), 10) || 0;

        const currentAmount = parseInt(inputPerValue === "0" ? inputPerValue : inputPerValue.replace(/,/g, ''), 10) || 0;

        // 퍼센트 계산: amount가 3이라면 3%를 의미하므로, 0.03으로 변환하여 곱합니다.
        const updatedAmount = currentAmount + (prepayReqAmount * (amount / 100));

        // 저장된 퍼센트
        const currentPerCent = parseInt(totalPerCentValue, 10) || 0;
        // 전체 퍼센트 
        const totalPerCent = currentPerCent + amount;

        setTotalPerCentValue(totalPerCent);
        setInputPerValue(formatNumber(updatedAmount));
    };

    // 퍼센트 초기화 버튼을 처리하는 함수
    const resetInputPer = () => {
        setInputPerValue(formatNumber(0));
        setTotalPerCentValue("");
    };

    // 충전 요청 금액이 달라지면 % 다시 계산 해야하니까 초기화 
    useEffect(() => {
        resetInputPer();
    }, [inputValue]);

    const [prePayData, setPrePayData] = useState([]);

    // 충전 요청 금액이 달라지면 % 다시 계산 해야하니까 초기화 
    useEffect(() => {
        setPrePayData([]);
    }, [inputValues]);

    function searchPrePayInfo(targetNumber) {

        axios.post('/api/pos/prepay/getList', {
            target: targetNumber,
        })
            .then(response => {
                setPrePayData(response.data); // 응답 데이터를 상태에 저장
            })
            .catch(error => {
                console.log(error);
            });
    }

    // 날짜 변환 함수 정의
    const formatDate = (dateString) => {
        const date = new Date(dateString); // ISO 문자열을 Date 객체로 변환
        const month = date.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        return `${month}월 ${day}일 ${hours}시 ${minutes}분`;
    };

    return (
        <>
            <Flex
                className="txHomeImageAndText">
                <Image src={main_sale} className="mainmenuImage" />
                <Text
                    className={prePayCardComponent === "prepay" ? "mainmenuText main-text-color" : "mainmenuText sub-text-color"}
                    onClick={() => { setPrePayCardComponent("prepay") }}
                >
                    선불 충전
                </Text>
                <Text
                    className={prePayCardComponent === "search" ? "mainmenuText main-text-color" : "mainmenuText sub-text-color"}
                    ml="10px"
                    onClick={() => { setPrePayCardComponent("search") }}
                >
                    선불 충전 조회
                </Text>
                <Image src={home} className="mainmenuHome" ml='auto' onClick={() => { setNowComponent('') }} />
            </Flex>
            <Box
                className="sub-background-color menuBox"
                overflowY='hidden'
                borderRadius='20px 20px 0px 0px'
                borderTop='1px solid white'
                zIndex='2'
                height='100%'
                width='100vw'
            // backgroundColor='rgba(100,200,220,1)'
            >
                <Box
                    mx='auto'
                    className="saleTableBox table-box"
                    backgroundColor='white'
                    h="90%"
                    p="10px"
                >
                    <Flex direction="row" justify="space-between" h="100%">
                        <Flex direction="column" w="40%" h="100%">
                            <Text fontSize="3xl" fontWeight="800" m="0 auto 0 auto">
                                전화번호 뒷 4자리 입력
                            </Text>
                            <KeyPadComponent
                                pwdInput={inputValues}
                                setPwdInput={setInputValues}
                                alertAni={alertAni}
                                btnClickFunction={handleInputChange} />
                        </Flex>
                        {/* 세로로 줄을 그릴 Box 추가 */}
                        <Box
                            width="1px"
                            height="100%"
                            backgroundColor="lightgray" // 연한 회색 줄
                            mx="4" // Flex 사이 간격 설정
                        />
                        {
                            prePayCardComponent === "prepay"
                                ?
                                <Flex direction="column" w="55%" h="100%">
                                    <Text fontSize="3xl" fontWeight="800" m="0 auto 0 auto">
                                        충전 금액 선택
                                    </Text>
                                    <AmountInputComponent
                                        inputValue={inputValue}
                                        resetInput={resetInput}
                                        handleAmountChange={handleAmountChange}
                                        inputPerValue={inputPerValue}
                                        resetInputPer={resetInputPer}
                                        handlePerChange={handlePerChange}
                                        totalPerCentValue={totalPerCentValue}
                                        inputValues={inputValues}
                                        prepayReq={prepayReq}
                                        hasEmptyValue={hasEmptyValue}
                                        prepayStatus={prepayStatus}
                                    />
                                </Flex>
                                :
                                <Flex direction="column" w="55%" h="100%" >
                                    <Text fontSize="3xl" fontWeight="800" m="0 auto 0 auto">
                                        선불 충전 카드 조회
                                    </Text>
                                    <Box overflowY="auto">
                                        {prePayData.map((item, index) => (
                                            <Box key={index} borderWidth="1px" borderRadius="lg" p={2} mb={4}>
                                                {/* 상태 표시 */}
                                                <Text fontSize="lg" fontWeight="bold" color={
                                                    item.issuance_status === "Issued" ? "blue" :
                                                        item.issuance_status === "Used" ? "green" :
                                                            item.issuance_status === "Reissued" ? "yellow" :
                                                                item.issuance_status === "Cancelled" ? "red" : "black"
                                                }>
                                                    {item.issuance_status === "Issued" ? "발급 완료" :
                                                        item.issuance_status === "Used" ? "사용 중" :
                                                            item.issuance_status === "Reissued" ? "재발급" :
                                                                item.issuance_status === "Cancelled" ? "환불됨" : ""}
                                                </Text>

                                                {/* Grid로 데이터 배치 */}
                                                <Grid templateColumns="repeat(18, 2fr)" gap={2} mt={4} fontSize="sm">
                                                    {/* 1행 */}
                                                    <GridItem colSpan={3} w="auto"><Text fontWeight="bold">발급 날짜:</Text></GridItem>
                                                    <GridItem colSpan={6}>{formatDate(item.issuance_create_at)}</GridItem>

                                                    {/* 2행 */}
                                                    <GridItem colSpan={3}><Text fontWeight="bold">발급 번호:</Text></GridItem>
                                                    <GridItem colSpan={6}>{item.issuance_number_key}</GridItem>

                                                    {/* 3행 */}
                                                    <GridItem colSpan={3}><Text fontWeight="bold">총 발급량:</Text></GridItem>
                                                    <GridItem colSpan={3}>{formatNumber(item.issuance_amount)}</GridItem>

                                                    {/* 4행 */}
                                                    <GridItem colSpan={3}><Text fontWeight="bold">구매 금액:</Text></GridItem>
                                                    <GridItem colSpan={3}>{formatNumber(item.issuance_req_amount)}</GridItem>

                                                    {/* 5행 */}
                                                    <GridItem colSpan={3}><Text fontWeight="bold">혜택 금액:</Text></GridItem>
                                                    <GridItem colSpan={3}>{formatNumber(item.issuance_reserve_amount)}</GridItem>

                                                    {item.issuance_allocate_user_id !== null
                                                        ?
                                                        <>
                                                            <GridItem colSpan={3}><Text fontWeight="bold">등록자:</Text></GridItem>
                                                            <GridItem colSpan={3}>{item.issuance_allocate_user_id || '미사용'}</GridItem>

                                                            <GridItem colSpan={3}><Text fontWeight="bold">등록 날짜:</Text></GridItem>
                                                            <GridItem colSpan={6}>{item.issuance_allocate_at ? formatDate(item.issuance_allocate_at) : '미사용'}</GridItem>
                                                        </>
                                                        : null
                                                    }
                                                </Grid>

                                                {/* 하단 버튼 */}
                                                <Box mt={4} textAlign="left">
                                                    <Button colorScheme="blue" mr={4} onClick={() => { alert("작업중..") }}>
                                                        재발급 하기
                                                    </Button>
                                                    <Button colorScheme="red" onClick={() => { alert("작업중..") }}>
                                                        환불 하기
                                                    </Button>
                                                </Box>
                                            </Box>
                                        ))}

                                    </Box>
                                    <Box pl="1" m="auto auto 0 auto">
                                        <Button colorScheme="green"
                                            w="30vw"
                                            display={hasEmptyValue ? "none" : ""}
                                            disabled={hasEmptyValue}
                                            onClick={() => { searchPrePayInfo(inputValues.toString().replaceAll(",", "")) }}
                                        >
                                            조회하기
                                        </Button>
                                    </Box>

                                </Flex>
                        }


                    </Flex>
                </Box>

            </Box>
        </>
    );
}