// React
import React, { useState, useEffect } from "react";
import { 
    Flex, Box, Image, Text, Button, 
    InputGroup, Input, InputRightElement,
    Select,
    useColorModeValue } from "@chakra-ui/react";
import axios from 'axios';
import { TEXT } from "react-dnd-html5-backend/dist/NativeTypes";

export default function AmountInputComponent({
    inputValue,  resetInput, handleAmountChange,
    inputPerValue,  resetInputPer, handlePerChange, totalPerCentValue,
    inputValues , prepayReq , hasEmptyValue , prepayStatus
}) {


    


    const [inputs, setInputs] = useState({option_group:""});


    function onChange(e) {
        const { value, name } = e.target;
        setInputs(prev => ({ ...prev, [name]: value }));
      }

    return (
        <Flex direction="column" mt='1vh'>
            <Text>충전 요청 금액</Text>
            {/* 선불 충전 발급 금액 선택 */}
            <InputGroup>
                <Input
                    placeholder="금액 입력"
                    value={inputValue}
                    readOnly
                    pattern="\d*"
                    inputMode="numeric"
                    size='md'
                    style={{fontWeight:"900"}}
                />
                <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={resetInput}>
                        x
                    </Button>
                </InputRightElement>
            </InputGroup>
            <Box  ml="0" pl="1" mt="1vh" mb="3px">
                <Button size="md" colorScheme="green" m={1} onClick={() => handleAmountChange(10000)}>+ 1 만</Button>
                <Button size="md" colorScheme="green" m={1} onClick={() => handleAmountChange(30000)}>+ 3 만</Button>
                <Button size="md" colorScheme="green" m={1} onClick={() => handleAmountChange(50000)}>+ 5 만</Button>
                <Button size="md" colorScheme="green" m={1} onClick={() => handleAmountChange(100000)}>+ 10 만</Button>
                <Button colorScheme="red" mr={3} size="md" onClick={resetInput}>
                    지우기
                </Button>
            </Box>

        {/* 추가 적립 퍼센트 선택 */}
{/*  inputPerValue, handleInputPerChangeToAmount, resetInputPer, handlePerChange, */}
        <Text>적립 퍼센트 선택 </Text>
            <InputGroup>
                <Input
                    placeholder="혜택 % 선택"
                    value={inputPerValue}
                    readOnly
                    pattern="\d*"
                    inputMode="numeric"
                    size='md'
                    style={{fontWeight:"900"}}
                />
                <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={resetInputPer}>
                        x
                    </Button>
                </InputRightElement>
            </InputGroup>
            <Box  ml="0" pl="1" mt="1vh">
                <Button size="md" colorScheme="green" m={1} onClick={() => handlePerChange(1)}>+ 1 %</Button>
                <Button size="md" colorScheme="green" m={1} onClick={() => handlePerChange(3)}>+ 3 %</Button>
                <Button size="md" colorScheme="green" m={1} onClick={() => handlePerChange(5)}>+ 5 %</Button>
                <Button size="md" colorScheme="green" m={1} onClick={() => handlePerChange(10)}>+ 10 %</Button>
                <Button colorScheme="red" mr={3} size="md" onClick={resetInputPer}>
                    지우기
                </Button>
            </Box>


            <Flex direction="column" alignItems="center" mt="10px">
                {/* 
                <Text fontSize="3xl" fontWeight="800" m="0 auto 0 auto">
                    선불 충전 발급 내용
                </Text>
                <Box >
                    <Flex alignItems="center"><Text fontSize="6xl">5자리숫자-{inputValues}</Text></Flex>
                </Box>
                */}
                <Box >
                    <Flex alignItems="center">
                        
                        <Text fontSize="4xl" fontWeight="600">
                        { (
                            parseInt(inputValue.replace(/,/g, ''), 10)
                            +
                            parseInt(inputPerValue.replace(/,/g, ''), 10)
                        ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0"  
                    } 
                        </Text>
                        <Text fontSize="3xl" ml="5px" > 포인트</Text>
                        <Text fontSize="3xl" ml="5px"> (</Text>
                        <Text fontSize="4xl" fontWeight="600"> {totalPerCentValue}</Text>
                        <Text fontSize="3xl" > %)</Text>
                    </Flex>
                </Box>

                <Box  ml="0" pl="1">
                    {prepayStatus ? 
                    <Button colorScheme={!hasEmptyValue && inputValue !== "0"  && inputPerValue !== "0"? "blue" : "red"} 
                    w="30vw" 
                    disabled={!(!hasEmptyValue && inputValue !== "0" && inputPerValue !== "0")}
                    onClick={() => prepayReq(inputValue,inputPerValue,inputValues)}
                    
                    >
                        발급하기
                    </Button>
                    :
                    <Button colorScheme="blue" 
                    w="30vw" 
                    isLoading
                    variant='outline'
                    loadingText='발급중'
                    />
                    }
                </Box>

            </Flex>
        </Flex>
    );
}