// React
import React, { useState, useEffect } from "react";
import { Box, FormControl, Flex, Table, Tbody, Icon,Text } from "@chakra-ui/react";
import { BsBackspace } from "react-icons/bs";
export default function KeyPadComponent({ pwdInput, setPwdInput, alertAni, btnClickFunction }) {

    const [number, setNumber] = useState(['', '', '', '', '', '', '', '', '', '']);
    const firstNumber = ([1, 2, 3, 4, 5, 6, 7, 8, 9, 0]);
    const numberCheck = ([true, true, true, true, true, true, true, true, true, true]);
    useEffect(() => {
        var list = [];
        var value;

        for (var i = 0; i < 10; i++) {
            list.push(firstNumber[i]);
            numberCheck[value] = false;
        }

        setNumber(list); // 무작위 숫자 배열 설정
    }, []);


    function allClear() {
        setPwdInput(['', '', '', '']);
    }

    function clearDigit() {
        const newInputValues = [...pwdInput];
        var index = -1;
        for (var i = 0; i < 4; i++) {
            if (newInputValues[i] !== '') {
                index = i;
            }
        }
        if (index !== -1) {
            newInputValues[index] = '';
            setPwdInput(newInputValues);
        }
    }

    return (
        <>
            <Box width='80%' align='center'
                mx='auto'
                display="flex"
                justifyContent="center" alignItems="center"
                height='30%'
            >
                <FormControl mt='1vh' width='100%' >
                    <Flex justify='center'>
                        {pwdInput.map((value, index) => (
                            <Box
                                key={index}
                                width='55px'
                                height='55px'
                                borderRadius='30%'
                                fontSize='18px'
                                textAlign='center'
                                margin='5px'
                                outline='none'
                                border='2px solid'
                                borderColor={(value !== '' ? '#FF8339' : '#C1B9B9')}
                                backgroundColor={(value !== '' ? '#FF8339' : '#fff')}
                                color={(value !== '' ? 'transparent' : '#000')}
                                className={`secondPwd ${alertAni}`}
                                alignContent="center"
                                // fontSize="md"
                            >
                                <Text color="Black" fontSize="3xl" fontWeight="800">{value}</Text>
                              
                                </Box>
                        ))}
                    </Flex>
                </FormControl>
            </Box>
            <Box width='100%' height='100%' mt='1vh' bgColor="Black">
                <Table className="mainBtnColor" textColor='white' h="100%">
                    <Tbody border="1px solid white"  >
                        {Array.from({ length: Math.ceil(number.length / 3) }).map((_, rowIndex) => {
                            const rowNumbers = number.slice(rowIndex * 3, rowIndex * 3 + 3);
                            if (rowIndex === 3) {
                                return (
                                    <Flex justify="space-evenly" key={rowIndex} height='25%'>
                                        <Box p={4} flex={1} textAlign="center" border="1px solid white" display="flex" justifyContent="center" alignItems="center" onClick={() => {
                                            allClear();
                                        }}
                                            sx={{
                                                transition: "transform 0.1s ease-in-out",
                                                ':active': {
                                                    transform: "scale(0.95)",
                                                    backgroundColor: "rgba(255,255,255,0.2)"
                                                }
                                            }}>
                                            전체 제거
                                        </Box>
                                        {rowNumbers.map((num, index) => (
                                            <Box key={index} p={4} border="1px solid white" textAlign="center" flex={1} display="flex" justifyContent="center" alignItems="center" onClick={() => {
                                                btnClickFunction(num);
                                            }}
                                                sx={{
                                                    transition: "transform 0.1s ease-in-out",
                                                    ':active': {
                                                        transform: "scale(0.95)",
                                                        backgroundColor: "rgba(255,255,255,0.2)"
                                                    }
                                                }}>
                                                {num}
                                            </Box>
                                        ))}
                                        <Box p={4} flex={1} textAlign="center" border="1px solid white" display="flex" justifyContent="center" alignItems="center" onClick={() => {
                                            clearDigit();
                                        }}
                                            sx={{
                                                transition: "transform 0.1s ease-in-out",
                                                ':active': {
                                                    transform: "scale(0.95)",
                                                    backgroundColor: "rgba(255,255,255,0.2)"
                                                }
                                            }}>
                                            <Icon as={BsBackspace} />
                                        </Box>
                                    </Flex>
                                );
                            }
                            return (
                                <Flex justify="space-evenly" key={rowIndex} height='25%'>
                                    {rowNumbers.map((num, index) => (
                                        <Box key={index} p={4} border="1px solid white" textAlign="center" flex={1} display="flex" justifyContent="center" alignItems="center" onClick={() => {
                                            btnClickFunction(num);
                                        }}
                                            sx={{
                                                transition: "transform 0.1s ease-in-out",
                                                ':active': {
                                                    transform: "scale(0.95)",
                                                    backgroundColor: "rgba(255,255,255,0.2)"
                                                }
                                            }}>
                                            {num}
                                        </Box>
                                    ))}
                                </Flex>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box >
        </>
    );
}
